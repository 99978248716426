import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../../components/seo"
import "./article.scss"
import Icon from "../../components/icon"
import BackButton from "../../components/back-button/back-button"
import { getPreviousPath } from "../../services/previous-path"
import { useDispatch, useSelector } from "react-redux"
import { fetchArticleData } from "../../store/actions/article"
import convertToImgUrl from "../../services/convert-img-url"
import _ from "lodash"
import { setNavDetailsView } from "../../store/actions/nav"
import DownLoadKML from '../../components/icon/kmlIcon';
import DownLoadGPS from "../../components/icon/gpsIcon"
import constants from "../../../constants";

const Article = ({ sectionId, subSectionId, articleId, location }) => {
  const dispatch = useDispatch()
  const {
    isLoading,
    data: {
      subsectionPhone: phone,
      subsectionEmail: email,
      subsectionWeb: web,
      subsectionDirection: address,
      subsectionName: title,
      subsectionDescription: content,
      kmlFileId: kmlFile,
      gpsTrackFileId: gpsFile,
      subsectionFacebook,
      subsectionTwitter,
      subsectionInstagram,
      subsectionPinterest,
      images,
    },
  } = useSelector(state => state.article)
  const [imageList, setImageList] = React.useState([])
  const [socials, setSocials] = React.useState([])
  const { formatMessage, locale } = useIntl()
  const previous = getPreviousPath({
    sectionId,
    subSectionId,
    articleId,
    locale,
  })

  React.useEffect(() => {
    if (!_.isEmpty(images)) {
      setImageList(images.map(({ id }) => convertToImgUrl(id)))
    }
    // console.log(imageList)
  }, [images])

  React.useEffect(() => {
    setSocials([
      { icon: "facebook", link: subsectionFacebook },
      { icon: "twitter", link: subsectionTwitter },
      { icon: "instagram", link: subsectionInstagram },
      { icon: "pinterest", link: subsectionPinterest },
    ])
  }, [
    subsectionFacebook,
    subsectionTwitter,
    subsectionInstagram,
    subsectionPinterest,
  ])

  React.useEffect(() => {
    dispatch(setNavDetailsView(true))
    dispatch(fetchArticleData(articleId, locale))
  }, [])

  return (
    <>
      <BackButton previous={previous} darkTheme />
      <SEO title={formatMessage({ id: "home.title" })} location={location} />
      <article className="article container-fluid">
        <header>
          <h1 className="article__title primary-heading">{title}</h1>
          <ul className="article__socials-list">
            {socials &&
              socials.map((social, i) => (
                <li key={i} className="article__socials-item">
                  {social.link && (
                    <a
                      href={social.link}
                      className="d-inline-block"
                      target="_blank"
                      rel="noopener noreferer"
                    >
                      <Icon
                        name={social.icon}
                        customClass="article__socials-icon"
                      />
                    </a>
                  )}
                </li>
              ))}
          </ul>
        </header>
        <main>
          <div
            id="carouselExampleIndicators"
            className="carousel slide article__slider"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              {imageList.map((img, i) => (
                <li
                  key={i}
                  data-target={`#carouselExampleIndicators`}
                  data-slide-to={i}
                  className={i === 0 ? "active" : ""}
                />
              ))}
            </ol>
            <div className="carousel-inner">
              {imageList.map((img, i) => (
                <div
                  className={`carousel-item ${i === 0 ? "active" : ""}`}
                  key={i}
                >
                  <img
                    className="d-block w-100"
                    src={img}
                    alt="First slide"
                    style={{ height: "40rem" }}
                  />
                </div>
              ))}
            </div>
            {_.size(imageList) > 1 && (
              <>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Next</span>
                </a>
              </>
            )}
          </div>
          <br />
          <div
            className="article__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </main>
        <br />
        <footer className="mt-4">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4">
            {address && (
              <div className="article__footer-item">
                <a
                  href={`https://www.google.es/maps/place/${address}`}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <Icon name={"location"} customClass="article__footer-icon" />
                  <span className="article__footer-text">{address}</span>
                </a>
              </div>
            )}
            {phone && (
              <div className="article__footer-item">
                <a href={`tel:${phone}`}>
                  <Icon name={"phone"} customClass="article__footer-icon" />
                  <span className="article__footer-text">{phone}</span>
                </a>
              </div>
            )}
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center  p-4">
            {email && (
              <div className="article__footer-item">
                <a href={`mailto:${email}`}>
                  <Icon name={"mail"} customClass="article__footer-icon" />
                  <span className="article__footer-text">{email}</span>
                </a>
              </div>
            )}
            {web && (
              <div className="article__footer-item">
                <a href={`${web}`} target="_blank" rel="noopener noreferer">
                  <Icon name={"sphere"} customClass="article__footer-icon" />
                  <span className="article__footer-text">{web}</span>
                </a>
              </div>
            )}
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4">
            {kmlFile && (
              <div className="article__footer-item">
                <a href={`${constants.SERVER_URL}media/stream/${kmlFile}`} target="_blank" rel="noreferrer noopener">
                    <DownLoadKML customClass="article__footer-icon" />
                    <span className="article__footer-text">{formatMessage({ id: "download.kml" })}</span>
                  </a>
              </div>
            )}
            {gpsFile && (
              <div className="article__footer-item">
                <a href={`${constants.SERVER_URL}media/stream/${gpsFile}`} target="_blank" rel="noreferrer noopener">
                    <DownLoadGPS customClass="article__footer-icon" />
                    <span className="article__footer-text">{formatMessage({ id: "download.gps" })}</span>
                </a>
              </div>
            )}
          </div>
        </footer>
      </article>
    </>
  )
}

export default Article
