import React from "react"
import { Download } from "../icon/download"
import PropTypes from "prop-types"

export const Document = ({image,title,uriDownload,text}) =>(
    <div className="section-card">
        <div
            className="section-card__header"
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className="section-card__tool-box">
              <h2 className="section-card__title">{title}</h2>
            </div>
        </div>
        <div className="section-card__body">
            <div className="d-flex align-items-center mb-2">
                <a href={`${uriDownload}`} target="_blank">
                    <Download customClasses="section-card__body-icon" />
                    <span>{text}</span>
                </a>
            </div>
        </div>
    </div>
)

Document.defaultProps = {
  image: "",
  title: "",
  uriDownload: ""
}
  
Document.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uriDownload: PropTypes.string.isRequired
}