import React from "react"
import PropTypes from "prop-types"
import { useIntl, Link } from "gatsby-plugin-intl"
import "./block-section.scss"
import BackButton from "../back-button/back-button"

const BlockSection = ({ image, content, title, link, previous, videoUrl=null }) => {
  const intl = useIntl()
  return (
    <section className="block-section">
      <header
        className="block-section__header"
        style={
          image
            ? { backgroundImage: `url(${image})` }
            : { backgroundColor: `white` }
        }
      > <Link to={link} className="block-section__title-wrapper">
        <h1 className="block-section__title">{title}</h1>
      </Link>
        {previous && (
          <BackButton previous={previous} customClass={"block-section__back"} />
        )}
      </header>

      {videoUrl && <div className="block-section__video" >
        <iframe width="1519" height="562" src={videoUrl} frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
      </div>}
      <div className="container-fluid">
        <div
          className="block-section__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  )
}

BlockSection.propTypes = {
  data: PropTypes.object,
}

export default BlockSection
