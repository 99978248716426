import React from "react"
import { Link } from "gatsby-plugin-intl"
import "./section-card.scss"
import Icon from "../icon"
import PropTypes from "prop-types"
import { UriComponent } from "../../services/uri-component"
import { useIntl } from "gatsby-plugin-intl"
import DownLoadKML from '../icon/kmlIcon'
import DownLoadGPS from "../icon/gpsIcon"

const SectionCard = ({
  town,
  image,
  title,
  link,
  address,
  coords,
  phone,
  email,
  web,
  kmlFile,
  gpsFile
}) => {
  console.log(town)
  const intl = useIntl()
  return <div className="section-card">
          <div
            className="section-card__header"
            style={{ backgroundImage: `url(${image})` }}
          >
            {link && (
              <Link to={link} className="section-card__link">
                {" "}
              </Link>
            )}
            <div className="section-card__tool-box">
              <h2 className="section-card__title">{title}</h2>
              <div className="section-card__description">
                {(coords || address) && (
                  <a
                    href={`https://www.google.com/maps/place/${
                      UriComponent.encode(address) || (coords && coords.join(","))
                    }`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Icon name={"location"} customClass={"section-card__pin-icon"} />
                  </a>
                )}
                <h3>{town}</h3>
              </div>
            </div>
          </div>
          {(phone || email || web || kmlFile || gpsFile) && (
            <div className="section-card__body">
              {phone && (
                <div className="d-flex align-items-center mb-2">
                  <a href={`tel:${phone}`}>
                    <Icon name={"phone"} customClass="section-card__body-icon" />
                    <span>{phone}</span>
                  </a>
                </div>
              )}{" "}
              {email && (
                <div className="d-flex align-items-center mb-2">
                  <a href={`mailto:${email}`}>
                    <Icon name={"mail"} customClass="section-card__body-icon" />
                    <span>{email}</span>
                  </a>
                </div>
              )}{" "}
              {web && (
                <div className="d-flex align-items-center mb-2">
                  <a href={`${web}`} target="_blank" rel="noreferrer noopener">
                    <Icon name={"sphere"} customClass="section-card__body-icon" />
                    <span>{web}</span>
                  </a>
                </div>
              )}{" "}
              {kmlFile && (
                <div className="d-flex align-items-center mb-2">
                  <a href={`${kmlFile}`} target="_blank" rel="noreferrer noopener">
                    <DownLoadKML customClass="section-card__body-icon" />
                    <span>{intl.formatMessage({ id: "download.kml" })}</span>
                  </a>
                </div>
              )}{" "}
              {gpsFile && (
                <div className="d-flex align-items-center mb-2">
                  <a href={`${gpsFile}`} target="_blank" rel="noreferrer noopener">
                    <DownLoadGPS customClass="section-card__body-icon" />
                    <span>{intl.formatMessage({ id: "download.gps" })}</span>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
}

SectionCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  address: PropTypes.string,
  coords: PropTypes.array,
  phone: PropTypes.string,
  email: PropTypes.string,
  web: PropTypes.string,
  kmlFile: PropTypes.string,
  gpsFile: PropTypes.string
}

export default SectionCard
