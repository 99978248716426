import React from "react"

export const Audio = ({muted,...otherProps}) =>(
    <svg {...otherProps} className="home-hero__audio" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="120px" height="120px" viewBox="0 0 120 120" enableBackground="new 0 0 120 120" xmlSpace="preserve">
        <rect y="39" width="23.628" height="41.998"/>
        <polygon points="46.929,107.436 18.052,80.998 18.052,39.667 46.929,12.563 53.555,60 "/>
        <path d="M46.929,12.563c0,0,6.625,23.824,6.625,47.437c0,23.613-6.625,47.436-6.625,47.436"/>
        {
            !muted &&
            <>
                <path d="M106.38,114.541l-9.779-5.035c0.114-0.224,11.712-23.197,11.712-51.188c0-27.802-13.417-50.66-13.553-50.888l9.441-5.645
                    c0.617,1.03,15.111,25.593,15.111,56.532C119.312,89.069,106.908,113.516,106.38,114.541z"/>
                <path d="M88.489,101.367l-9.779-5.035c0.081-0.159,8.533-16.937,8.533-37.337c0-20.337-9.756-36.863-9.854-37.027l9.438-5.651
                    c0.466,0.778,11.417,19.333,11.417,42.679C98.243,82.154,88.888,100.594,88.489,101.367z"/>
                <path d="M71.573,86.925l-9.779-5.035c0.046-0.091,5.22-10.405,5.22-22.895c0-12.3-5.947-22.498-6.008-22.599l9.445-5.639
                    c0.309,0.516,7.562,12.807,7.562,28.238C78.014,74.239,71.837,86.414,71.573,86.925z"/>
            </>
        }
    </svg>
)