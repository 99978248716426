import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import { useDispatch, useSelector } from "react-redux"
import { fetchCookiesPolicy } from "../store/actions/cookies-policy"
import { setNavDetailsView } from "../store/actions/nav"

const Cookies = ({ location }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {
    isLoading,
    data: { content },
  } = useSelector(state => state.cookiesPolicy)
  React.useEffect(() => {
    dispatch(setNavDetailsView(true))
    dispatch(fetchCookiesPolicy(intl.locale))
  }, [])
  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "cookiesPolicy.title" })}
        location={location}
      />
      <div className="container-fluid py-5">
        <h1 className="primary-heading">
          {intl.formatMessage({ id: "cookiesPolicy.title" })}
        </h1>
        <div className="p-2" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  )
}

export default Cookies
