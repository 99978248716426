import React from 'react';
import './notification-banner.scss';
import { useIntl } from "gatsby-plugin-intl"

const NotificationBanner = ()=>{
  const { formatMessage } = useIntl();
  const message  = formatMessage({
    id: "notificationBanner.message",
  })
  return <div className="notification-banner">
    <p className="notification-banner__text">{message}</p>
  </div>
}

export default NotificationBanner;
