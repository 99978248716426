import React from "react"
import PropTypes from "prop-types"
import Swiper from "react-id-swiper"
import "./hero-section.scss"
import { useIntl } from "gatsby-plugin-intl"
import { Audio } from "../icon/audio"

const HeroSection = ({
  videoUrl,
  images,
  logo,
  isBackgroundVideo,
  isLogoImage,
  title,
  description,
}) => {
  const { locale } = useIntl()
  const [muted, setMuted] = React.useState(true)
  const swiperConfig = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 0,
    containerClass: "home-hero__slider",
    effect: "fade",
    speed: 2000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  }

  return (
    <section className="home-hero">
      {isLogoImage && (
        <div
          className="home-hero__alt-logo"
          style={{ backgroundImage: "url(" + logo + ")" }}
        />
      )}
      {!isLogoImage && (
        <div className="home-hero__alt-content">
          <h2 className="home-hero__title">{title}</h2>
          <h5 className="home-hero__description">{description}</h5>
        </div>
      )}
      {!isBackgroundVideo && images && (
        <Swiper {...swiperConfig}>
          {images.map((img, i) => (
            <div style={{ backgroundImage: "url(" + img + ")" }} key={i} />
          ))}
        </Swiper>
      )}
      {isBackgroundVideo && (
        <>
          <video
            autoPlay={true}
            playsInline
            controls={false}
            muted={muted}
            loop={true}
            className="home-hero__player"
          >          
            <source src={videoUrl} />
            Your browser does not support the video tag.
          </video> 
          <Audio muted={muted} onClick={()=>setMuted(!muted)} />
        </>       
      )}
    </section>
  )
}

HeroSection.defaultProps = {
  video: false,
}

HeroSection.propTypes = {
  video: PropTypes.bool,
}

export default HeroSection
