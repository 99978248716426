import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import { useDispatch, useSelector } from "react-redux"
import { fetchLegalData } from "../store/actions/legal"
import { setNavDetailsView } from "../store/actions/nav"

const Legal = ({ location }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {
    isLoading,
    data: { content },
  } = useSelector(state => state.legal)
  React.useEffect(() => {
    dispatch(setNavDetailsView(true))
    dispatch(fetchLegalData(intl.locale))
  }, [])
  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "legalNotice.title" })}
        location={location}
      />
      <div className="container-fluid py-5">
        <h1 className="primary-heading">
          {intl.formatMessage({ id: "legalNotice.title" })}
        </h1>
        <div className="p-2" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  )
}

export default Legal
