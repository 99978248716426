import React from "react"
import SEO from "../components/seo"
import BlockSection from "../components/block-section"
import SectionCard from "../components/section-card"
import Filter from "../components/filter/filter"
import { useIntl } from "gatsby-plugin-intl"
import { getPreviousPath } from "../services/previous-path"
import { UriComponent } from "../services/uri-component"
import { useDispatch, useSelector } from "react-redux"
import { fetchSection, fetchSubsection } from "../store/actions/section"
import convertToImgUrl from "../services/convert-img-url"
import {
  fetchSubSectionChildren,
  fetchSubSections,
} from "../store/actions/sub-sections"
import _ from "lodash"
import { setNavDetailsView } from "../store/actions/nav"
import { getYoutubeFormattedVideoUrl } from "../services/youtubeUrlService"
import constants from "../../constants";
const translateSlug = require("../services/translate-slug")

const Section = ({ sectionId, subSectionId, location }) => {
  const dispatch = useDispatch()
  const { locale } = useIntl()
  const sectionState = useSelector(state => state.section)
  const subSectionsState = useSelector(state => state.subSections)
  const [subsections, setSubsections] = React.useState([])
  const [showTown,setShowTown] = React.useState({});
  const [section, setSection] = React.useState({})
  const previous = getPreviousPath({
    sectionId,
    subSectionId,
    locale,
  })
  const sectionSlug = translateSlug(locale, "section")
  const subSectionSlug = translateSlug(locale, "subsection")
  const articleSlug = translateSlug(locale, "article")
  const fullPath = id => {
    if (!subSectionId)
      return `/${sectionSlug}/${sectionId}/${subSectionSlug}/${id}/`
    else
      return `/${sectionSlug}/${sectionId}/${subSectionSlug}/${subSectionId}/${articleSlug}/${id}/`
  }
  React.useEffect(() => {
    dispatch(setNavDetailsView(true))
    if (subSectionId) {
      dispatch(fetchSubsection(subSectionId, locale))
      dispatch(fetchSubSectionChildren(subSectionId, locale))
    } else if (sectionId) {
      dispatch(fetchSection(sectionId, locale))
      dispatch(fetchSubSections(sectionId, locale))
    }
  }, [])

  React.useEffect(() => {
    const { data } = subSectionsState
    if (!_.isEmpty(data)) {
      setSubsections(
        data.map(sub => ({
          town: sub.town,
          title: sub.subsectionName,
          email: sub.subsectionEmail,
          phone: sub.subsectionPhone,
          web: sub.subsectionWeb,
          address: sub.subsectionDirection,
          link: fullPath(UriComponent.encode(sub.subsectionName)),
          image: convertToImgUrl(
            sub.type === "detail"
              ? sub.images[0]?.id
              : sub.sectionHeaderImage?.id
          ),
          kmlFile: sub.kmlFileId && `${constants.SERVER_URL}media/stream/${sub.kmlFileId}`,
          gpsFile: sub.gpsTrackFileId && `${constants.SERVER_URL}media/stream/${sub.gpsTrackFileId}`
        }))
      )
    }else{
      setSubsections([])
    }
  }, [subSectionsState])

  React.useEffect(() => {
    const { data } = sectionState
    if (!_.isEmpty(data)) {
      const {videoSection, sectionHeaderImage,sectionDescription,sectionTitle,subsectionName} = data;
      setSection({
        image: convertToImgUrl(
          sectionHeaderImage?.id
        ),
        content: sectionDescription,
        title: !subSectionId ? sectionTitle : subsectionName,
        previous,
        videoUrl: getYoutubeFormattedVideoUrl(videoSection)
      })
      if( data.showTownFilter ){
        setShowTown({
          id: data._id,
          show: data.showTownFilter
        })
      }
    }
  }, [sectionState])

  return (
    <>
      <SEO
        title={!subSectionId ? sectionId : subSectionId}
        location={location}
      />
      <BlockSection {...section}/>
      <section className="container-fluid">
        {
          (showTown.show && subSectionId && subsections.length > 0) && <Filter name={subSectionId} subSection__Id={showTown.id} />
        }
        <div className="row">
          {!_.isEmpty(subsections) &&
            subsections.map((sub, i) => (
              <div className="col-md-6 col-lg-4" key={i}>
                <SectionCard
                  {...{
                    ...sub,
                  }}
                />
              </div>
            ))}
        </div>
      </section>
    </>
  )
}

export default Section
