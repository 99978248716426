var es = require("../../locales/slugs/es.json")
var en = require("../../locales/slugs/en.json")
var eu = require("../../locales/slugs/eu.json")
var fr = require("../../locales/slugs/fr.json")
var de = require("../../locales/slugs/de.json")
var ca = require("../../locales/slugs/ca.json")

function translateSlug (lang, key) {
  const slugs = {
    es,
    en,
    eu,
    fr,
    de,
    ca
  }
  return slugs[lang][key]
}

module.exports =  translateSlug
