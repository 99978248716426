import React from "react"
import './filter.scss'
import { useDispatch, useSelector } from "react-redux"
import { fetchTownsData } from "../../store/actions/towns"
import { fetchSubSectionChildren } from "../../store/actions/sub-sections"
import { useIntl } from "gatsby-plugin-intl"
import _ from "lodash"

const Filter = ({ name, subSection__Id }) =>{
    const { locale, formatMessage } = useIntl()
    const dispatch = useDispatch()
    const [openSelect,setOpenSelect] = React.useState(false)
    const townsState = useSelector(state => state.towns)
    const [towns,setTowns] = React.useState([])
    const [selectedTown,setSelectedTown] = React.useState("")

    React.useEffect(()=>{
        dispatch(fetchTownsData(subSection__Id))
    },[])

    React.useEffect(()=>{
        const { data } = townsState
        if (!_.isEmpty(data)) {
            setTowns(Object.values(data).sort((a,b)=> a._id.localeCompare(b._id)))
        }
    },[townsState])

    const handleSelectedTown = (town) =>{
        setSelectedTown(town)
        setOpenSelect(false);
        dispatch(fetchSubSectionChildren(name, locale, town))
    }

    return(
        <div className="form-fields">
            <div className="select-town" onClick={()=>setOpenSelect(!openSelect)}>
                <div>{ selectedTown ? selectedTown : formatMessage({ id: "filtersTowns.town" }) }</div>
                <svg className={ openSelect ? 'open-select' : ''} width="9" height="16">
                    <path d="M.3 14.3c-.4.4-.4 1.02 0 1.4.38.4 1 .4 1.4 0l7-7c.4-.4.4-1.02 0-1.4l-7-7C1.3-.1.68-.1.3.3c-.4.38-.4 1 0 1.4L6.6 8 .3 14.3z"></path>
                </svg>
            </div>
            <div className={`select-open ${!openSelect ? 'show-options' : ''}`}>
                {
                    towns.length == 0 
                    ?   <div className="select-open__options">{ formatMessage({ id: "filtersTowns.empty" }) }</div>
                    :   towns.map(town =>(
                            <div key={town['_id']} className={`select-open__options ${selectedTown === town['_id'] && 'disabled-options'}`} onClick={()=>handleSelectedTown(town['_id'])}>{town['_id']}</div>
                        ))
                }
                {
                    selectedTown != "" && <div className="select-open__options" onClick={()=>handleSelectedTown("")}>{ formatMessage({ id: "filtersTowns.clean" }) }</div>
                }
            </div>
        </div>
    )
}

export default Filter;