import Icon from "../icon"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import "./back-button.scss"
import { useIntl } from "gatsby-plugin-intl"

const BackButton = ({ previous, customClass, darkTheme }) => {
  const { formatMessage } = useIntl()
  return (
    <div
      className={`back-button customClass ${customClass} ${
        darkTheme ? "back-button--dark" : ""
      }`}
    >
      <Link to={previous.link} className="back-button__button hovered-item">
        <Icon name={"chevron-left"} customClass="back-button__icon" />
        {previous.name && previous.name}
        {!previous.name && formatMessage({ id: "home.gotoHomeTooltip" })}
      </Link>
    </div>
  )
}

export default BackButton
