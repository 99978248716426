import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"
import BlockSection from "../components/block-section"
import Map from "../components/map"
import { UriComponent } from "../services/uri-component"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../components/loader"
import convertToImgUrl from "../services/convert-img-url"
import _ from "lodash"
import { setNavDetailsView } from "../store/actions/nav"
import { fetchSectionDocumentsData } from "../store/actions/section-document"
import { getYoutubeFormattedVideoUrl } from "../services/youtubeUrlService"
const translateSlug = require("../services/translate-slug")

const Home = ({ location }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const homeState = useSelector(state => state.home)
  const sectionDocumentState = useSelector(state=> state.sectionDocument)
  const sectionsState = useSelector(state => state.homeSections)
  const geolocationState = useSelector(state => state.geolocation)
  const [sections, setSections] = React.useState([])
  const [hasCoords, setHasCoords] = React.useState(false)
  const [heroData, setHeroData] = React.useState({})
  const [geolocation, setGeolocation] = React.useState({})
  const { locale, formatMessage } = useIntl()
  const [sectionDocument,setSectionDocument] = React.useState({})

  const isBackgroundVideoType = type => type === true
  const isLogoImageType = type => type === false

  React.useEffect(() => {
    dispatch(setNavDetailsView(false))
    dispatch( fetchSectionDocumentsData() )
  }, [])

  React.useEffect(()=>{
    const { data } = sectionDocumentState
    if(!_.isEmpty(data)) {
      setSectionDocument({
        image: data.headerImage.url,
        title: data.title[locale],
        content: ""
      })
    }
  }, [sectionDocumentState])

  React.useEffect(() => {
    setHasCoords(geolocation.points && geolocation.points.length > 0)
  }, [geolocation])

  React.useEffect(() => {
    const { data } = homeState
    if (!_.isEmpty(data)) {
      const {
        homeContentLogo,
        headerImage,
        headerVideo,
        homeBackgroundType,
        homeContentType,
        homeTextContent,
        homeDescContent,
      } = homeState.data
      setHeroData({
        logo: homeContentLogo && convertToImgUrl(homeContentLogo.id),
        images: headerImage.map(img => convertToImgUrl(img.id)),
        videoUrl: headerVideo && convertToImgUrl(headerVideo.id),
        isBackgroundVideo: isBackgroundVideoType(homeBackgroundType),
        isLogoImage: isLogoImageType(homeContentType),
        title: (homeTextContent && homeTextContent[locale]) || "",
        description: (homeDescContent && homeDescContent[locale]) || "",
      })
    }
  }, [homeState])

  React.useEffect(() => {
    const { data } = geolocationState
    if (!_.isEmpty(data)) {
      const geoData = {
        points: data
          .map(item => ({
            image: item.images[0] ? convertToImgUrl(item.images[0].id) : null,
            detailName: item.detail,
            sectionName: item.section,
            subsectionName: item.subsection,
            coordinates: [
              parseFloat(item.coords.lon),
              parseFloat(item.coords.lat),
            ],
          }))
          .filter(({ coordinates: coords }) => coords[0] && coords[1]),
      }
      setGeolocation({ ...geoData, center: geoData.points[0]?.coordinates })
    }
  }, [geolocationState])

  React.useEffect(() => {
    const { data } = sectionsState
    setSections(
      data.map(section => ({
        image: section.sectionHeaderImage
          ? convertToImgUrl(section.sectionHeaderImage.id)
          : null,
        content: section.sectionDescription,
        title: section.sectionTitle,
        videoUrl: getYoutubeFormattedVideoUrl(section.videoSection)
      }))
    )
  }, [sectionsState])

  return (
    <>
      <SEO title={formatMessage({ id: "home.title" })} location={location} />
      {!homeState.isLoading && <HeroSection {...heroData} />}
      <br />
      <div className="mt-5">
        <Loader visible={homeState.isLoading} />
      </div>
      {sections.map((section, i) => (
        <BlockSection
          {...{
            ...section,
            link: `/${translateSlug(locale, "section")}/${UriComponent.encode(
              section.title
            )}`,
          }}
          key={i}
        />
      ))}
      {
        sectionDocumentState.data.enabled 
        && (sectionDocumentState.isLoading
        ? <Loader visible={sectionDocumentState.isLoading} />
        : <BlockSection 
            {...{
              ...sectionDocument,
              link: `/${translateSlug(locale, "documents")}`
            }}
          />)
      }
      {hasCoords && <Map geolocation={geolocation} />}
    </>
  )
}

export default Home
