import React from "react"
import PropTypes from "prop-types"

const Icon = ({ name, customClass, color }) => (
  <svg className={customClass} style={{ fill: color }}>
    <use xlinkHref={`#icon-${name}`} />
  </svg>
)

Icon.defaultProps = {
  customClass: "",
  color: "",
}

Icon.propTypes = {
  name: PropTypes.string,
  customClass: PropTypes.string,
  color: PropTypes.string,
}

export default Icon
