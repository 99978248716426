import PropTypes from "prop-types"
import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import { useDispatch, useSelector } from "react-redux"
import { toggleSidebarVisibility } from "../../store/actions/sidebar"

import "./header.scss"
import Icon from "../icon"
import convertToImgUrl from "../../services/convert-img-url"
import { generalSearch, generalSearchCleanUp } from "../../store/actions/search"

const Header = () => {
  const {
    detailsView: details,
    data: {
      navBackgroundTypeTransparent,
      navLogo,
      navBackgroundColor,
      navColorButtons,
      navDetailLogo,
      navDetailBackgroundColor,
      navDetailColorButtons,
    },
  } = useSelector(state => state.nav)
  const [logo, setLogo] = React.useState("")
  const [bgColor, setBgColor] = React.useState("transparent")
  const [btnColor, setBtnColor] = React.useState("white")
  const intl = useIntl()
  const dispatch = useDispatch()
  const toggleSidebar = () => {
    dispatch(generalSearchCleanUp() )
    setTimeout(dispatch(toggleSidebarVisibility()), 200)
  }

  React.useEffect(() => {
    setLogo(convertToImgUrl(details ? navDetailLogo.id : navLogo.id))
  }, [navDetailLogo, navLogo, details])

  React.useEffect(() => {
    setBgColor(
      details
        ? navDetailBackgroundColor
        : navBackgroundTypeTransparent
        ? "transparent"
        : navBackgroundColor
    )
  }, [
    navBackgroundTypeTransparent,
    navBackgroundColor,
    navDetailBackgroundColor,
    details,
  ])

  React.useEffect(() => {
    setBtnColor(details ? navDetailColorButtons : navColorButtons)
  }, [navColorButtons, navDetailColorButtons, details])

  return (
    <nav
      className={`header ${!details ? "header--main" : ""}`}
      style={{ background: bgColor }}
    >
      <div className="header__wrapper">
        <Link to={"/"} className="hovered-item">
          <img
            title={intl.formatMessage({
              id: "home.gotoHomeTooltip",
            })}
            className="header__logo-img"
            src={logo}
            alt="Ribera de Navarra logo"
          />
        </Link>
        <button
          className="btn btn-link"
          title={intl.formatMessage({
            id: "header.openSidebarTooltip",
          })}
          onClick={toggleSidebar}
        >
          <Icon
            name={"hamburger"}
            customClass={"header__icon-hamburger"}
            color={btnColor}
          />
        </button>
      </div>
    </nav>
  )
}

Header.propTypes = {
  details: PropTypes.bool,
}

Header.defaultProps = {
  details: true,
}

export default Header
