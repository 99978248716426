import React from "react"
import "./footer.scss"
import { useIntl, Link } from "gatsby-plugin-intl"
import { useSelector } from "react-redux"
import _ from "lodash"
import convertToImgUrl from "../../services/convert-img-url"
const translateSlug = require("../../services/translate-slug")

const Footer = () => {
  const { data } = useSelector(state => state.footer)
  const intl = useIntl()
  const [logos, setLogos] = React.useState([])
  const [icons, setIcons] = React.useState([])
  const [email, setEmail] = React.useState("turismo@consorcioeder.es")
  const [iconColor, setIconColor] = React.useState("#fff730")

  React.useEffect(() => {
    if (!_.isEmpty(data)) {
      const { socialMedia, logos, colorIconFooter, EmailFooter } = data
      setLogos(
        logos.map(({ link, logo: { id } }) => ({
          link,
          url: convertToImgUrl(id),
        }))
      )
      setIcons(
        socialMedia.map(({ link, icon: { id } }) => ({
          link,
          url: convertToImgUrl(id),
        }))
      )
      setIconColor(colorIconFooter)
      setEmail(EmailFooter)
    }
  }, [data])

  return (
    <footer className="footer container-fluid">
      <div className="d-flex flex-column justify-content-between flex-md-row w-100">
        <div className="footer__col1">
          <div className="d-flex align-items-center mb-4">
            {logos.map((img, i) => (
              <a
                href={img.link}
                className="mr-3"
                key={i}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="footer__logo" src={img.url} />
              </a>
            ))}
          </div>
          <Link to={`/${translateSlug(intl.locale, "legalNotice")}`}>
            {intl.formatMessage({ id: "legalNotice.title" })}
          </Link>
        </div>
        <div className="footer__col2">
          <div className="d-flex align-items-center mb-4">
            {icons.map((icon, i) => (
              <a
                href={icon.link}
                className="mr-3"
                key={i}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div
                  style={{
                    backgroundColor: iconColor,
                    WebkitMaskImage: "url(" + icon.url + ")",
                  }}
                  className="footer__social-icon"
                />
              </a>
            ))}
          </div>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
