import React from "react"
import PropTypes from "prop-types"
import "./loader.scss"
import Icon from "../icon"

const Loader = ({ visible }) => (
  <>
    {visible && (
      <div className="loader">
        <Icon name={"spinner"} customClass={"loader__icon"} />
      </div>
    )}
  </>
)

Loader.propTypes = {
  visible: PropTypes.bool.isRequired,
}

export default Loader
