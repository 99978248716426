import translateSlug from "../translate-slug"
import { UriComponent } from "../uri-component"

export default ({ sectionId, subSectionId, articleId, locale }) => {
  const sectionSlug = translateSlug(locale, "section")
  const subSectionSlug = translateSlug(locale, "subsection")

  if (sectionId && subSectionId && articleId) {
    return {
      name: UriComponent.decode(subSectionId),
      link: `/${sectionSlug}/${sectionId}/${subSectionSlug}/${subSectionId}/`,
    }
  }
  if (sectionId && subSectionId && !articleId) {
    return {
      name: UriComponent.decode(sectionId),
      link: `/${sectionSlug}/${sectionId}/`,
    }
  }
  return { link: "/" }
}
