import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import BlockSection from '../components/block-section'
import { useDispatch, useSelector } from "react-redux"
import documentsImg from '../images/documentsImg.jpeg'
import { getPreviousPath } from "../services/previous-path"
import { setNavDetailsView } from "../store/actions/nav"
import { fetchDocumentsData } from "../store/actions/documents"
import _ from "lodash"
import { Document } from "../components/documents/document"
import { fetchSectionDocumentsData } from "../store/actions/section-document"

const Documents = ({ location }) => {
  const [allDocuments,setAllDocuments] = React.useState({})
  const documentsState = useSelector(state => state.documents)
  const sectionDocumentState = useSelector(state=>state.sectionDocument)
  const dispatch = useDispatch()
  const { locale, formatMessage } = useIntl()

  const previous = getPreviousPath({
    locale,
  })
  const [section,setSection] = React.useState({})

  React.useEffect(()=>{
    dispatch(setNavDetailsView(true))
    dispatch(fetchDocumentsData())
    dispatch( fetchSectionDocumentsData())
  },[])

  React.useEffect(()=>{
    const { data } = sectionDocumentState
    if(!_.isEmpty(data)){
      setSection({
        image: data.headerImage.url,
        title: data.title[locale],
        content: "",
        previous
      })
    }
  }, [sectionDocumentState])

  React.useEffect(()=>{
    const { data } = documentsState

    if(!_.isEmpty(data)){
      setAllDocuments(data)
    }
  },[documentsState])

  return (
    <>
      <SEO
        title={formatMessage({ id: "documents.title" })}
        location={location}
      />
      <BlockSection {...section}/>
      <section className="container-fluid">
        <div className="row">
          {
            Object.values(allDocuments).map(item=>(
              <div className="col-md-6 col-lg-4">
                <Document
                  key={item._id}
                  image={item.image ? item.image.url : documentsImg}
                  title={item.title}
                  uriDownload={item.url}
                  text={formatMessage({ id: "download.download"})}
                />
              </div>
            ))
          }
        </div>
      </section>
    </>
  )
}

export default Documents
