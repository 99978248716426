import React from "react"
import PropTypes from "prop-types"

const DownLoadGPS = ({ customClass }) =>(
	<svg className={customClass} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} xmlSpace="preserve">
        <g>
            <g>
                <g>
                    <path d="M296.661,414.012c-10.65,0-23.142,12.254-27.699,23.253c-3.558,8.593-2.5,16.614,2.91,22.016
                        c5.094,5.094,12.655,8.021,20.745,8.021c8.09,0,15.65-2.927,20.753-8.021c5.666-5.666,7.049-13.995,3.891-23.441
                        c-2.125-6.383-6.34-13.133-11.255-18.048C303.565,415.352,300.254,414.012,296.661,414.012z M301.303,447.206
                        c-3.789,3.789-13.636,3.686-17.323,0.102c-0.12-0.367-0.111-2.5,2.15-6.238c2.944-4.864,7.1-8.294,9.353-9.515
                        c2.423,2.876,4.523,6.477,5.589,9.677C302.02,444.058,302.106,446.413,301.303,447.206z"/>
                    <path d="M85.069,215.381c3.405,0,6.622-2.048,7.945-5.41c1.732-4.386-0.427-9.344-4.813-11.068
                        c-21.973-8.653-39.535-26.223-48.179-48.196c-1.715-4.386-6.656-6.545-11.059-4.821c-4.386,1.724-6.545,6.682-4.821,11.068
                        c10.368,26.359,31.437,47.445,57.805,57.83C82.978,215.185,84.036,215.381,85.069,215.381z"/>
                    <path d="M392.499,294.571c-2.466,0.708-5.837,1.673-6.733,1.741c-11.793,0-19.268,9.131-24.721,15.795
                        c-2.091,2.551-6.135,7.296-8.243,7.177c-3.857-1.929-7.919-2.901-12.049-2.901c-10.061,0-18.773,5.982-20.719,14.242
                        c-1.374,5.803,0.648,13.756,10.419,18.637c13.858,6.929,28.186,14.089,34.56,20.463c4.386,4.386,11.972,6.903,20.821,6.903
                        c13.44,0,31.309-6.528,37.444-24.943c2.424-7.253,8.218-12.8,13.824-18.159c6.502-6.229,12.655-12.109,12.74-20.557
                        c0.06-4.983-2.15-9.745-6.554-14.157c-5.999-5.99-14.165-9.037-24.286-9.037C409.293,289.775,399.582,292.548,392.499,294.571z
                        M432.777,312.738c-0.401,1.681-4.651,5.751-7.475,8.457c-6.443,6.161-14.455,13.833-18.21,25.096
                        c-4.215,12.638-18.449,13.269-21.257,13.269c-4.975,0-8.098-1.314-8.747-1.894c-8.346-8.346-23.236-15.787-39.006-23.671
                        c-0.034-0.017-0.06-0.026-0.094-0.043c1.604-0.649,4.574-0.7,7.185,0.597c2.756,1.382,5.581,2.082,8.405,2.082
                        c9.446,0,15.411-7.287,20.668-13.713c3.849-4.685,7.808-9.54,11.52-9.54c3.029,0,6.554-0.998,11.418-2.398
                        c6.46-1.843,14.498-4.139,21.82-4.139c5.504,0,9.498,1.323,12.211,4.036C432.401,312.073,432.717,312.738,432.777,312.738z"/>
                    <path d="M110.942,187.153l0.008,43.247c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533l-0.009-43.247
                        c30.814-3.874,55.27-28.339,59.136-59.153H230.4c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-43.255
                        C183.27,80.119,158.814,55.671,128,51.797V8.533C128,3.823,124.177,0,119.467,0s-8.533,3.823-8.533,8.533v43.264
                        c-30.814,3.874-55.27,28.322-59.145,59.136H8.533c-4.71,0-8.533,3.823-8.533,8.533S3.823,128,8.533,128h43.255
                        C55.654,158.822,80.119,183.287,110.942,187.153z M119.467,68.275c28.237,0,51.2,22.963,51.2,51.2
                        c0,28.22-22.946,51.174-51.157,51.2c-0.017,0-0.026-0.009-0.034-0.009c-0.017,0-0.017,0.009-0.034,0.009
                        c-28.228-0.017-51.174-22.972-51.174-51.2C68.267,91.238,91.23,68.275,119.467,68.275z"/>
                    <path d="M509.884,259.43c-0.648-4.676-5.018-7.927-9.617-7.287c-4.668,0.649-7.936,4.958-7.287,9.626
                        c1.297,9.378,1.954,18.927,1.954,28.365c0,112.111-90.539,203.46-202.342,204.792c-30.345-19.388-58.786-43.128-46.114-72.687
                        c22.75-53.103,1.579-72.892-7.467-81.357l-1.698-1.613c-2.586-2.577-5.504-4.326-8.073-5.879
                        c-6.246-3.746-7.927-4.753-4.574-14.814c2.219-6.682,5.171-9.796,8.576-13.406c7.893-8.346,13.926-16.649,13.926-40.781
                        c0-14.046-7.125-25.25-19.55-30.737c-14.089-6.238-32.905-4.036-42.854,5.001c-6.212,5.649-8.303,13.338-5.726,21.077
                        c6.084,18.219-7.817,44.553-33.792,64.034c-6.878,5.154-8.934,2.79-10.155,1.382c-7.62-8.781-7.031-33.749-0.776-40.004
                        c4.19-4.19,5.436-9.54,3.328-14.31c-6.912-15.676-51.012-14.473-59.75-14.054c-0.358,0.017-0.657,0.196-0.998,0.256
                        c-0.529,0.085-1.024,0.196-1.527,0.375c-0.58,0.222-1.092,0.486-1.596,0.819c-0.41,0.256-0.777,0.512-1.135,0.836
                        c-0.452,0.41-0.819,0.853-1.178,1.348c-0.299,0.41-0.555,0.811-0.777,1.271c-0.256,0.529-0.418,1.075-0.563,1.664
                        c-0.077,0.324-0.265,0.58-0.307,0.913c-1.084,8.823-1.545,16.563-1.545,25.873c0,102.315,69.282,190.882,168.474,215.39
                        c0.683,0.171,1.374,0.256,2.048,0.256c3.831,0,7.322-2.603,8.277-6.494c1.135-4.574-1.664-9.199-6.238-10.325
                        C149.274,466.338,85.333,384.572,85.333,290.133c0-5.914,0.222-11.145,0.674-16.469c14.396-0.051,26.692,1.638,32.939,3.55
                        c-9.788,15.283-8.713,45.329,3.251,59.119c8.576,9.882,21.018,10.291,33.289,1.084c32.776-24.585,48.384-57.208,39.74-83.081
                        c-0.273-0.845-0.546-1.638,1.024-3.063c4.48-4.079,15.915-5.803,24.465-2.014c6.315,2.79,9.387,7.74,9.387,15.13
                        c0,19.26-3.883,23.373-9.259,29.056c-3.985,4.224-8.951,9.472-12.373,19.738c-7.672,23.049,4.634,30.438,11.989,34.85
                        c1.903,1.143,3.695,2.219,4.787,3.302c0.623,0.623,1.331,1.289,2.108,2.014c7.501,7.023,21.47,20.079,3.439,62.157
                        c-20.446,47.71,32.273,80.973,54.784,95.181c1.365,0.853,2.944,1.314,4.557,1.314C412.467,512,512,412.476,512,290.133
                        C512,279.91,511.283,269.585,509.884,259.43z"/>
                    <path d="M244.642,91.452c14.481-3.789,36.25-6.118,45.491-6.118c14.49,0,28.894,1.51,42.923,4.506
                        c-0.307,12.279,4.992,20.702,8.405,26.112c0.939,1.502,2.236,3.55,2.389,3.831c0,30.609-6.263,35.584-6.187,35.61
                        c-13.833,0-36.531,12.348-45.065,28.279c-5.24,9.779-4.813,20.113,1.186,29.124c4.113,6.17,7.654,13.03,11.085,19.669
                        c7.253,14.046,15.3,29.628,27.93,29.628c2.68,0,5.564-0.7,8.687-2.261c9.95-4.975,17.084-19.115,23.979-32.794
                        c3.038-6.025,7.501-14.865,10.146-17.673c8.661,1.178,12.894,9.011,18.62,21.419c4.215,9.131,8.576,18.56,17.135,22.844
                        c14.43,7.202,61.85-0.341,88.055-11.469c0.35-0.145,0.589-0.435,0.913-0.623c0.503-0.299,0.981-0.58,1.408-0.964
                        c0.41-0.367,0.725-0.785,1.05-1.22c0.333-0.435,0.64-0.862,0.879-1.348c0.247-0.495,0.384-0.998,0.521-1.536
                        c0.145-0.529,0.273-1.033,0.316-1.579c0.034-0.563-0.034-1.118-0.119-1.698c-0.043-0.375,0.034-0.734-0.068-1.109
                        c-17.911-66.219-66.56-121.301-130.15-147.337c-4.369-1.783-9.353,0.299-11.136,4.659c-1.784,4.361,0.307,9.344,4.668,11.127
                        c56.311,23.066,99.874,70.835,117.922,128.649c-26.547,9.344-60.57,12.177-66.62,9.182c-3.209-1.604-6.434-8.576-9.276-14.729
                        c-5.948-12.885-14.097-30.532-34.901-31.454c-10.786-0.512-16.811,11.725-24.602,27.179
                        c-4.574,9.062-11.477,22.767-16.981,25.472c-3.166-0.751-9.455-12.928-13.21-20.198c-3.473-6.724-7.407-14.345-12.049-21.299
                        c-2.517-3.772-2.62-7.356-0.341-11.605c5.862-10.94,23.45-19.268,30.02-19.268c15.428,0,23.253-17.724,23.253-52.676
                        c0-4.966-2.552-9.011-5.018-12.919c-3.61-5.734-7.339-11.665-5.129-21.786c0.981-4.497-1.783-8.96-6.246-10.095
                        c-17.673-4.454-35.977-6.716-54.391-6.716c-11.503,0-34.79,2.739-49.809,6.682c-4.565,1.195-7.287,5.854-6.093,10.411
                        C235.426,89.916,240.085,92.672,244.642,91.452z"/>
                    <path d="M119.467,153.609c18.825,0,34.133-15.309,34.133-34.133s-15.309-34.133-34.133-34.133s-34.133,15.309-34.133,34.133
                        S100.642,153.609,119.467,153.609z M119.467,102.409c9.412,0,17.067,7.654,17.067,17.067c0,9.412-7.654,17.067-17.067,17.067
                        s-17.067-7.654-17.067-17.067C102.4,110.063,110.054,102.409,119.467,102.409z"/>
                    <path d="M199.031,150.4c-8.602,22.101-26.189,39.774-48.247,48.478c-4.386,1.732-6.536,6.69-4.804,11.076
                        c1.323,3.354,4.54,5.402,7.945,5.402c1.041,0,2.099-0.196,3.123-0.597c26.47-10.453,47.565-31.65,57.89-58.172
                        c1.707-4.395-0.469-9.344-4.855-11.051C205.688,143.821,200.738,146.005,199.031,150.4z"/>
                    <path d="M28.979,92.971c1.033,0.401,2.091,0.597,3.132,0.597c3.405,0,6.622-2.048,7.945-5.402
                        c8.627-21.854,26.095-39.364,47.932-48.034c4.386-1.741,6.528-6.699,4.787-11.085c-1.732-4.369-6.699-6.511-11.085-4.779
                        C55.484,34.671,34.526,55.68,24.175,81.903C22.451,86.289,24.602,91.238,28.979,92.971z"/>
                    <path d="M150.929,40.132c22.016,8.73,39.552,26.402,48.119,48.478c1.306,3.379,4.531,5.453,7.953,5.453
                        c1.024,0,2.065-0.188,3.089-0.58c4.386-1.707,6.571-6.647,4.864-11.042c-10.274-26.487-31.317-47.693-57.719-58.18
                        c-4.403-1.741-9.344,0.41-11.085,4.787C144.41,33.434,146.551,38.391,150.929,40.132z"/>
                </g>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>

)

DownLoadGPS.defaultProps = {
	customClass: "",
  }
  
  DownLoadGPS.propTypes = {
	customClass: PropTypes.string,
  }
  
  export default DownLoadGPS