import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "./lang-selector.scss"
import { toggleSidebarVisibility } from "../../store/actions/sidebar"
import { useDispatch, useSelector } from "react-redux"

const LangSelector = () => {
  const dispatch = useDispatch()
  const { data:langs } = useSelector((state=>state.langs))
  const { locale } = useIntl()
  const toggleSidebar = () => dispatch(toggleSidebarVisibility())

  return (
    <div className="lang-selector dropdown">
      {langs.length>0 && <>
        <button
          className="lang-selector__toggle btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {locale.toUpperCase()}
        </button>
        <div
          className="lang-selector__menu dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          {langs.map((lang, i) => (
            <a
              href={`/${lang.code}/`}
              key={i}
              className={`lang-selector__item dropdown-item ${
                locale === lang.code ? "active" : ""
              }`}
              onClick={() => toggleSidebar()}
            >
              {lang.name}
            </a>
          ))}
        </div>
      </>}
    </div>
  )
}
export default LangSelector
