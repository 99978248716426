import React from "react"
import PropTypes from "prop-types"

const DownLoadKML = ({ customClass }) =>(
	<svg className={customClass} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="10px" height="10px" viewBox="0 0 636 636" style={{ enableBackground:'new 0 0 636 636'}} xmlSpace="preserve">
	<g>
		<path d="M563.977,227.494h-15.271v-73.688c0-0.463-0.072-0.922-0.134-1.391c-0.024-2.923-0.962-5.797-2.959-8.074L422.983,4.266
			c-0.03-0.037-0.066-0.052-0.091-0.089c-0.73-0.815-1.583-1.495-2.484-2.082c-0.269-0.18-0.536-0.332-0.816-0.487
			c-0.779-0.423-1.607-0.779-2.46-1.035c-0.231-0.061-0.438-0.155-0.67-0.216C415.537,0.137,414.574,0,413.6,0H112.235
			C98.474,0,87.294,11.191,87.294,24.941v202.546H72.022c-19.683,0-35.646,15.957-35.646,35.649v185.378
			c0,19.675,15.963,35.646,35.646,35.646h15.272v126.898c0,13.75,11.18,24.941,24.941,24.941h411.53
			c13.749,0,24.94-11.191,24.94-24.941V484.16h15.271c19.681,0,35.646-15.965,35.646-35.646V263.143
			C599.624,243.444,583.658,227.494,563.977,227.494z M112.235,24.947h288.894v127.604c0,6.89,5.59,12.47,12.471,12.47h110.165V227.5
			h-411.53V24.947z M428.75,426.947h-35.269l-2.374-61.093c-0.707-19.175-1.419-42.38-1.419-65.577h-0.713
			c-4.975,20.354-11.594,43.09-17.756,61.783l-19.418,62.268h-28.184l-17.041-61.792c-5.206-18.694-10.65-41.437-14.44-62.259h-0.475
			c-0.941,21.534-1.659,46.159-2.838,66.059l-2.85,60.611H252.59l10.19-159.579h48.062l15.625,53.262
			c4.966,18.475,9.946,38.361,13.503,57.055h0.706c4.494-18.462,9.949-39.543,15.162-57.293l17.043-53.03h47.112L428.75,426.947z
			M110.795,426.947V267.369h35.752v70.542h0.709c3.556-6.155,7.34-11.831,10.894-17.519l36.227-53.03h44.277l-52.799,67.942
			l55.63,91.63h-42.14l-39.068-68.911l-13.737,16.824v52.087h-35.746V426.947z M523.765,604.306h-411.53V484.16h411.53V604.306z
			M556.11,426.947h-99.679V267.369h36.224v129.266h63.468v30.312H556.11z"/>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	</svg>
)

DownLoadKML.defaultProps = {
	customClass: "",
  }
  
  DownLoadKML.propTypes = {
	customClass: PropTypes.string,
  }
  
  export default DownLoadKML
