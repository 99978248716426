/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../header"
import Footer from "../footer"
import "./layout.scss"
import Sidebar from "../sidebar"
import { fetchGeoData } from "../../store/actions/geolocation"
import { fetchHomeSections } from "../../store/actions/home-sections"
import { fetchHomeData } from "../../store/actions/home"
import { fetchSeoData } from "../../store/actions/seo"
import { useIntl } from "gatsby-plugin-intl"
import { fetchFooterData } from "../../store/actions/footer"
import { fetchNavData } from "../../store/actions/nav"
import { useDispatch, useSelector } from "react-redux"
import { fetchAvailableLangs } from "../../store/actions/lang"
import { fetchCookiesPolicy, updateCookiesComponentVisibility } from "../../store/actions/cookies-policy"
import CookiesPolicy from "../cookies-policy"
import NotificationBanner from "../notification-banner/notification-banner"
import constants from "../../../constants"

const Layout = ({ children }) => {
  const dispatch = useDispatch()
  const { locale } = useIntl()
  const {componentVisible:isCookiesVisible,data:{content}} = useSelector((state)=>state.cookiesPolicy);

  React.useEffect(() => {
    const cookies = localStorage.getItem(constants.COOKIES_STORAGE_KEY)
    if (!cookies){
      dispatch(updateCookiesComponentVisibility())
    }
  }, [])

  React.useEffect(() => {
    dispatch(fetchGeoData(locale))
    dispatch(fetchHomeSections(locale))
    dispatch(fetchHomeData())
    dispatch(fetchSeoData(locale))
    dispatch(fetchFooterData())
    dispatch(fetchNavData(locale))
    dispatch(fetchAvailableLangs())
    dispatch(fetchCookiesPolicy(locale))
  }, [locale])

  return (
    <>
      {(locale=='eu'||locale=='de')&&<NotificationBanner/>}
      <div className="position-relative">
        <Header />
        <main className="position-relative">{children}</main>
        <Footer />
      </div>
      {isCookiesVisible && <CookiesPolicy/>}
      <Sidebar />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
}

Layout.defaultProps = {
  meta: {
    transparentHeader: false,
  },
}

export default Layout
