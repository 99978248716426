import React from "react"
import { Location, redirectTo, Router } from "@reach/router"
import Home from "../containers/home"
import Legal from "../containers/legal"
import Section from "../containers/section"
import Article from "../containers/article"
import Documents from "../containers/documents"
import NotFoundPage from "./404"
import Layout from "../components/layout"
import Cookies from "../containers/cookies"
const translateSlug = require("../services/translate-slug")

const App = ({
  pageContext: {
    intl: { languages },
  },
}) => {
  return (
    <div className="app">
      <Layout>
        <Location>
          {() => (
            <Router basepath={"/"}>
              <RedirectTo
                path="es/birdwatching"
                to="/es/seccion/Birdwatching"
              />
              {languages.map((language, i) => (
                <RouterWrapper path={language} key={i}>
                  <Home path="/" />
                  <Legal path={`${translateSlug(language, "legalNotice")}`} />
                  <Cookies path={`${translateSlug(language, "cookiesPolicy")}`} />
                  <Section
                    path={`${translateSlug(language, "section")}/:sectionId`}
                  />
                  <Section
                    path={`${translateSlug(
                      language,
                      "section"
                    )}/:sectionId/${translateSlug(
                      language,
                      "subsection"
                    )}/:subSectionId`}
                  />
                  <Article
                    path={`/${translateSlug(
                      language,
                      "section"
                    )}/:sectionId/${translateSlug(
                      language,
                      "subsection"
                    )}/:subSectionId/${translateSlug(
                      language,
                      "article"
                    )}/:articleId`}
                  />
                  <Documents 
                    path={`/${translateSlug(
                      language,
                      "documents"
                    )}`}
                  />
                  <NotFoundPage default />
                </RouterWrapper>
              ))}
            </Router>
          )}
        </Location>
      </Layout>
    </div>
  )
}

const RouterWrapper = ({ children }) => children
const RedirectTo = ({ to }) => {
  React.useEffect(() => redirectTo(to), [])

  return <></>
}

export default App
