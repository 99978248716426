import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useIntl, Link } from "gatsby-plugin-intl"
import "./sidebar.scss"
import { toggleSidebarVisibility } from "../../store/actions/sidebar"
import Icon from "../icon"
import LangSelector from "../lang-selector"
import { UriComponent } from "../../services/uri-component"
import { generalSearch, generalSearchCleanUp } from "../../store/actions/search"
import _ from "lodash"
import { fetchSection, fetchSubsection } from "../../store/actions/section"
import {
  fetchSubSectionChildren,
  fetchSubSections,
} from "../../store/actions/sub-sections"
import { fetchArticleData } from "../../store/actions/article"
import convertToImgUrl from "../../services/convert-img-url"
const translateSlug = require("../../services/translate-slug")

const Sidebar = () => {
  const dispatch = useDispatch()
  const { locale, formatMessage } = useIntl()
  const isOpen = useSelector(state => state.sidebar.open)
  const { data, isLoading } = useSelector(state => state.search)
  const {
    data: {
      menuColorButtons,
      menuBackgroundTypeImage,
      menuLogo,
      menuBackgroundImage,
      menuBackgroundColor,
    },
  } = useSelector(state => state.nav)
  const [content, setContent] = React.useState([])
  const [logo, setLogo] = React.useState(null)
  const [navBg, setNavBg] = React.useState(null)
  const [searchKey, setSearchKey] = React.useState("")
  const toggleSidebar = () => dispatch(toggleSidebarVisibility())

  const openSectionPage = title => {
    const id = UriComponent.encode(title)
    dispatch(toggleSidebarVisibility())
    dispatch(fetchSection(id, locale))
    dispatch(fetchSubSections(id, locale))
  }
  const openSubsectionPage = title => {
    const id = UriComponent.encode(title)
    dispatch(toggleSidebarVisibility())
    dispatch(fetchSubsection(id, locale))
    dispatch(fetchSubSectionChildren(id, locale))
  }
  const openDetailsPage = title => {
    const id = UriComponent.encode(title)
    dispatch(toggleSidebarVisibility())
    dispatch(fetchArticleData(id, locale))
  }
  React.useEffect(() => {
    if (!searchKey) {
      dispatch(generalSearchCleanUp())
    } else {
      dispatch(generalSearch(UriComponent.encode(searchKey), locale))
    }
  }, [searchKey])

  React.useEffect(() => {
    setLogo(convertToImgUrl(menuLogo?.id))
    if (menuBackgroundImage) {
      setNavBg(convertToImgUrl(menuBackgroundImage?.id))
    }
  }, [menuBackgroundImage, menuLogo])

  React.useEffect(() => {
    if (!_.isNil(data)) {
      setContent(
        data.map(section => {
          const { sectionTitle } = section
          const sectionLink = `${translateSlug(
            locale,
            "section"
          )}/${UriComponent.encode(sectionTitle)}`
          return {
            title: sectionTitle,
            link: sectionLink,
            subsections:
              section.subsection &&
              section.subsection
                .filter(({ subsectionName }) => subsectionName)
                .map(subsection => {
                  const { subsectionName } = subsection
                  const subsectionLink = `${sectionLink}/${translateSlug(
                    locale,
                    "subsection"
                  )}/${UriComponent.encode(subsectionName)}`
                  return {
                    title: subsectionName,
                    link: subsectionLink,
                    details:
                      subsection.detail &&
                      subsection.detail
                        .filter(({ subsectionName }) => subsectionName)
                        .map(detail => {
                          const { subsectionName } = detail
                          const detailLink = `${subsectionLink}/${translateSlug(
                            locale,
                            "article"
                          )}/${UriComponent.encode(subsectionName)}`
                          return {
                            title: subsectionName,
                            link: detailLink,
                          }
                        }),
                  }
                }),
          }
        })
      )
    }
  }, [data])

  return (
    <aside
      className={`sidebar ${isOpen ? "show" : ""}`}
      style={
        menuBackgroundTypeImage
          ? { backgroundImage: `url(${navBg})` }
          : { background: menuBackgroundColor || "#800848" }
      }
    >
      <div className="sidebar__wrapper">
        <header className="sidebar__header">
          <Link to={"/"} onClick={toggleSidebar} className="hovered-item">
            <img
              title={formatMessage({
                id: "home.gotoHomeTooltip",
              })}
              className="sidebar__logo"
              src={logo}
              alt="Ribera de Navarra logo"
            />
          </Link>
          <div className="d-flex align-items-center ml-auto">
            <LangSelector />
            <span className="hidden-md">
              <form className="sidebar__form">
                <Icon
                  name={"search"}
                  customClass={"sidebar__icon-search"}
                  color={menuColorButtons}
                />
                <input
                  onKeyUp={({ target: { value } }) => setSearchKey(value)}
                  type="search"
                  className="sidebar__search-box"
                  placeholder={formatMessage({
                    id: "sidebar.inputPlaceholder",
                  })}
                />
              </form>
            </span>
            <button
              className="btn btn-link"
              title={formatMessage({
                id: "sidebar.closeSidebarTooltip",
              })}
              onClick={toggleSidebar}
            >
              <Icon
                name={"close"}
                customClass={"sidebar__icon-close"}
                color={menuColorButtons}
              />
            </button>
          </div>
        </header>
        <div className="text-center visible-md mt-4">
          <form className="sidebar__form">
            <Icon
              name={"search"}
              customClass={"sidebar__icon-search"}
              color={menuColorButtons}
            />
            <input
              onKeyUp={({ target: { value } }) => setSearchKey(value)}
              type="search"
              className="sidebar__search-box"
              placeholder={formatMessage({
                id: "sidebar.inputPlaceholder",
              })}
            />
          </form>
        </div>
        <div className="sidebar__body">
          <div className="row">
            {content.map((section, i) => (
              <div className="col-md-6 col-lg-4 sidebar__col" key={i}>
                <h3 className="sidebar__col-header">
                  <Link
                    onClick={() => openSectionPage(section.title)}
                    className="hovered-item"
                    to={`/${section.link}`}
                  >
                    {section.title}
                  </Link>
                </h3>
                <ul className="sidebar__list">
                  {section.subsections &&
                    section.subsections.map((subsection, j) => (
                      <li key={j} className="ml-3 sidebar__list-item">
                        <Link
                          onClick={() => openSubsectionPage(subsection.title)}
                          className="hovered-item text-uppercase"
                          to={`/${subsection.link}`}
                        >
                          {subsection.title}
                        </Link>
                        <ul className="sidebar__list">
                          {subsection.details &&
                            subsection.details.map((detail, j) => (
                              <li
                                key={j}
                                className="ml-4 sidebar__list-item sidebar__list-item--dark"
                              >
                                <Link
                                  onClick={() => openDetailsPage(detail.title)}
                                  className="hovered-item"
                                  to={`/${detail.link}`}
                                >
                                  {detail.title}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
