import React from 'react';
import './cookies-policy.scss';
import { updateCookiesComponentVisibility } from "../../store/actions/cookies-policy"
import { useDispatch, useSelector } from "react-redux"
import constants from "../../../constants"
import { useIntl,Link } from "gatsby-plugin-intl"
const translateSlug = require("../../services/translate-slug")

const CookiesPolicy = ()=> {
  const dispatch = useDispatch()
  const { formatMessage,locale } = useIntl()
  const handleClick =()=>{
    localStorage.setItem(constants.COOKIES_STORAGE_KEY,'S')
    dispatch(updateCookiesComponentVisibility(false))
  }
  return <div className="cookies-policy">
    <p className="cookies-policy__text" >
      <span dangerouslySetInnerHTML={{ __html: formatMessage({
          id: "cookiesPolicy.content",
        }) }}/>
      <Link to={`/${translateSlug(locale, "cookiesPolicy")}`} className="cookies-policy__link">{formatMessage({
      id: "cookiesPolicy.seeMore"
    })}</Link>
    </p>

    <button className="cookies-policy__button"
            onClick={handleClick}>{formatMessage({
      id: "cookiesPolicy.acceptButton",
    }) }
    </button>
  </div>
}

export default CookiesPolicy;
